import { Star20Filled } from '@fluentui/react-icons'
import { styled, css } from '@styles/theme'

export const cardSimulateStyles = {
	Container: styled('div', {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		padding: '32px',
		gap: '32px',
		justifyContent: 'space-between',

		width: '100%',
		height: '100%',
		minHeight: '199px',

		background: '$neutral10',
		border: '1px solid $neutral50',
		borderRadius: '12px',
		cursor: 'pointer',

		'&:hover': {
			h6: {
				color: '$blue20',
			},
		},

		'@md': {
			flex: '1 1 328px',
		},

		'@2xl': {
			minWidth: '320px',
		},
	}),

	content1: css({
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
		marginTop: '25px',
	}),

	content2: css({
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		width: '100%',

		paddingTop: '20px',
	}),

	containerRating: css({}),

	StarFilled: styled(Star20Filled, {
		color: '$gold',
	}),
	StarEmpty: styled(Star20Filled, {
		color: '$neutral60',
	}),
}
